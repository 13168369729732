import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchOrders, markOrderAsCompleted, markOrderAsInProcess } from "features/orders/orderSlice";

const OrderManager = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleOrderDetails = (orderId: string) => {
    const order = orders.find((order: any) => order._id === orderId);
    setSelectedOrder(order);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleMarkAsInProcess = (orderId: string) => {
    dispatch(markOrderAsInProcess({ orderId }));
  };
  
  const handleMarkAsCompleted = (orderId: string) => {
    dispatch(markOrderAsCompleted({ orderId }));
  };

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Order ID</th>
                <th className="px-4 py-2">Customer Email</th>
                <th className="px-4 py-2">Total Price</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any) => (
                <tr key={order.orderId}>
                  <td className="border px-4 py-2">{order.orderId}</td>
                  <td className="border px-4 py-2">{order.user?.email || 'User Deleted'}</td>
                  <td className="border px-4 py-2">{order.total}</td>
                  <td className={`border px-4 py-2 ${order.status === "Pending" ? "bg-yellow-500" : order.status === "Paid" ? "bg-green-500" : order.status === "In Process" ? "bg-blue-500" : order.status === "Completed" ? "bg-green-800" : "bg-gray-500"}`}>
                    {order.status}
                  </td>
                  <td className="border px-4 py-2">
                    <ButtonPrimary onClick={() => handleOrderDetails(order._id)} className="mx-2">
                      View Details
                    </ButtonPrimary>
                    {order.status === "Paid" && (
                      <ButtonPrimary onClick={() => handleMarkAsInProcess(order._id)} className="mx-2">
                        Mark as In Process
                      </ButtonPrimary>
                    )}
                    {order.status === "In Process" && (
                      <ButtonPrimary onClick={() => handleMarkAsCompleted(order._id)} className="mx-2">
                        Mark as Completed
                      </ButtonPrimary>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedOrder && (
        <Modal isOpen={showModal} onClose={closeModal}>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Order Details</h2>
            <div className="text-white">
              <p className="mb-2"><strong>Order ID:</strong> {selectedOrder.orderId}</p>
              <p className="mb-2"><strong>Customer Email:</strong> {selectedOrder.user?.email || 'User Deleted'}</p>
              <p className="mb-2"><strong>Date:</strong> {new Date(selectedOrder.date).toLocaleDateString()}</p>
              <p className="mb-2"><strong>Time:</strong> {selectedOrder.time}</p>
              <h3 className="mb-2"><strong>Address:</strong></h3>
              <p className="mb-2"><strong>Name:</strong> {selectedOrder.address.name}</p>
              <p className="mb-2"><strong>Street:</strong> {selectedOrder.address.street}</p>
              <p className="mb-2"><strong>City:</strong> {selectedOrder.address.city}</p>
              <p className="mb-2"><strong>Country:</strong> {selectedOrder.address.country}</p>
              <p className="mb-2"><strong>Status:</strong>
                <span className={`inline-block py-1 px-2 rounded ${selectedOrder.status === "Pending" ? "bg-yellow-500" : selectedOrder.status === "Completed" ? "bg-green-500" : "bg-gray-500"}`}>
                  {selectedOrder.status}
                </span>
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OrderManager;