import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CartItem {
  id: string;
  type: "Package" | "Service" | "Product";
  name: string;
  price: number;
  image?: string;
  quantity: number;
}

export interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: JSON.parse(localStorage.getItem("cartItems") || "[]"),
};



const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem(state, action: PayloadAction<CartItem>) {
      const item = state.items.find((item) => item.id === action.payload.id);
      if (!item) {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },
    removeItem(state, action: PayloadAction<string>) {
      state.items = state.items.filter((item) => item.id !== action.payload);
      localStorage.setItem("cartItems", JSON.stringify(state.items));
    },
    clearCart(state) {
      state.items = [];
      localStorage.removeItem("cartItems");
    },
    removeDeletedProductOrService(state, action: PayloadAction<string>) {
      state.items=state.items.filter((item)=>item.id!==action.payload)
      localStorage.setItem("cartItems", JSON.stringify(state.items));
  },
},
});

export const { addItem, removeItem, clearCart,removeDeletedProductOrService } = cartSlice.actions;
export default cartSlice.reducer;
